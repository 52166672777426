import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { CircularProgress, Collapse, Container, Fab, Grow, InputAdornment, LinearProgress, Paper, Stack, TextField, Typography } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingContext } from '../context/LoadingContext';
import { ErrorContext, Toast } from 'stockpoint-common';
import client from '../../api';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import ClearIcon from '@mui/icons-material/Clear';
import { Scrollbars } from 'react-custom-scrollbars-2';
import PromoUpsertDialog from './PromoUpsertDialog';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PromoProductsDialog from './PromoProductsDialog';
import ConfirmationDialog from '../custom/ConfirmationDialog';
import { PromoBaseContext } from '../context/PromoBaseContext';
import FilterField from '../custom/table/FilterField';
import FilterPanelCollapsible from '../custom/table/FilterPanelCollapsible';



const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
        isSortable: true,
        filterAvailable: true,
    },
    {
        id: 'star_value',
        numeric: false,
        disablePadding: false,
        label: 'Star Value',
        isSortable: true,
        filterAvailable: false,
    },
    {
        id: 'date_create',
        numeric: false,
        disablePadding: false,
        label: 'Create Date',
        isSortable: true,
        filterAvailable: false,

    },
    {
        id: 'actions',
        numeric: false,
        disablePadding: false,
        label: '',
        isSortable: false,
        filterAvailable: false,
    }
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ fontWeight: "700", backgroundColor: "#f5f5f5" }}
                    >
                        {headCell.isSortable && (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};


export default function Promos() {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const filterModel = useRef([]);
    const [filtersVisible, setFiltersVisible] = useState(false);

    const { setIsLoading } = useContext(LoadingContext);
    const [isLoaded, setIsLoaded] = useState(false);

    const { setError } = useContext(ErrorContext);

    const [showPromoProductsDialog, setShowPromoProductsDialog] = useState(false);
    const [showUpsertDialog, setShowUpsertDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [submitData, setSubmitData] = useState(null);

    const [promoSelected, setPromoSelected] = useState(undefined);
    const [promos, setPromos] = useState([])

    const { promoBaseId } = useContext(PromoBaseContext)


    const loadPromos = async () => {
        try {
            setError(null);
            setIsLoading(true);
            const params = { limit: rowsPerPage, offset: page * rowsPerPage };

            //console.log(filterModel.current);
            const resp = await client.post('/promo-page', { order: { orderBy, direction: order }, filter: filterModel.current, ...params });
            const items = resp.data.data;
            //setShowLoadMore(items.length !== 0 && items.length === params.limit);
            setPromos(items);

        } catch (err) {
            if (err.response?.status === 403) {
                setError('Your account is not authorized to access Stock Point Manager.');
                await signOut(auth);
            } else {
                setError(err.response?.data?.error || err.message);
            }
        } finally {
            setIsLoading(false);
            setIsLoaded(true);
        }
    };

    const deletePromo = async () => {
        try {
            setIsLoading(true);
            const resp = await client.post('promo-delete', { id: promoSelected.id });
            setSubmitData(resp);
        } catch (err) {
            console.log(err.response.data.error);
            setError(err.response.data.error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(async () => {
        await loadPromos();
    }, [order, orderBy, page, rowsPerPage])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const applyFilters = async () => {
        if (page != 0) {
            setPage(0);
        } else {
            await loadPromos();
        }
    }

    const handleFilterFieldChange = (name, value) => {
        let filter = filterModel.current.find(function (el) { return el.name === name });
        if (value.length == 0) {
            filterModel.current = filterModel.current.filter((el) => el.name !== name);
        } else {
            if (filter) {
                filter.value = value;
            } else {
                filterModel.current.push({ name: name, value: value });
            }
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const isNextPageAvailable = () => {
        return promos.length < rowsPerPage;
    };

    const handleCloseDialog = async (needRelaod) => {
        if (needRelaod) await loadPromos();
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, rowsPerPage - promos.length) : 0;


    const classes = useStyles();

    return (
        <>
            {showDeleteDialog && (
                <ConfirmationDialog
                    isOpen={showDeleteDialog}
                    handleClose={(res) => {
                        setShowDeleteDialog(false);
                        if (res) deletePromo();
                        handleCloseDialog(res);
                    }}>
                    <Typography>Are you sure you want delete {promoSelected.name}?</Typography>
                </ConfirmationDialog>
            )}
            {showUpsertDialog && (
                <PromoUpsertDialog
                    isOpen={showUpsertDialog}
                    promo={promoSelected}
                    handleClose={(res) => {
                        setShowUpsertDialog(false);
                        handleCloseDialog(res);
                    }}
                />
            )}
            {showPromoProductsDialog && (
                <PromoProductsDialog
                    isOpen={showPromoProductsDialog}
                    promo={promoSelected}
                    handleClose={() => { setShowPromoProductsDialog(false); }}
                />
            )}
            <Container style={{ marginTop: "8px" }}>
                <FilterPanelCollapsible
                    isLoaded={isLoaded}
                    headCells={headCells}
                    handleFilterFieldChange={handleFilterFieldChange}
                    applyFilters={applyFilters}
                    visibleDefault={true} />
                <Paper sx={{ marginTop: 3 }}  >
                    <TableContainer sx={{ maxHeight: 650 }} style={{ minWidth: 750 }}>
                        <Scrollbars autoHeight={true} autoHeightMax={650}>
                            <Table
                                stickyHeader
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    filtersVisible={filtersVisible}
                                />
                                <TableBody>
                                    {promos.map((p, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell style={{
                                                    color: p.id === promoBaseId ? "#3525b5de" : "rgba(0, 0, 0, 0.87)",
                                                    fontWeight: p.id === promoBaseId ? "600" : "400"
                                                }}>{p.name}</TableCell>
                                                <TableCell>{p.star_value}</TableCell>
                                                <TableCell>{new Date(p.date_create).toLocaleString(window.navigator.language)}</TableCell>
                                                <TableCell>
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="flex-start">
                                                        <Tooltip title="Promo products" placement="top">
                                                            <IconButton aria-label="edit" size='small'
                                                                onClick={() => {
                                                                    setPromoSelected(p);
                                                                    setShowPromoProductsDialog(true);
                                                                }}>
                                                                <FormatListBulletedIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Edit promo" placement="top">
                                                            <IconButton aria-label="edit" size='small'
                                                                onClick={() => {
                                                                    setPromoSelected(p);
                                                                    setShowUpsertDialog(true);
                                                                }}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        {p.id != promoBaseId && (
                                                            <Tooltip title="Delete promo" placement="top">
                                                                <IconButton onClick={() => {
                                                                    setPromoSelected(p);
                                                                    setShowDeleteDialog(true);
                                                                }}
                                                                    aria-label="delete" size='small'>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>)}
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (dense ? 47 : 67) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={4} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Scrollbars>
                    </TableContainer>
                </Paper>
                <TablePagination
                    style={{ paddingRight: "90px" }}
                    rowsPerPageOptions={[10, 15, 25, 50, 100]}
                    component="div"
                    count={-1}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    showFirstButton={true}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    nextIconButtonProps={{ disabled: isNextPageAvailable() }}
                />
            </Container>
            <Fab
                className={classes.fab}
                color="primary"
                aria-label="add"
                onClick={() => {
                    setPromoSelected(undefined);
                    setShowUpsertDialog(true);
                }}
            >
                <AddIcon />
            </Fab>
            <Toast
                isOpen={!!submitData}
                dismiss={() => setSubmitData(null)}
                message="Successful!"></Toast>
        </>

    );
}
