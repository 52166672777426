/* eslint-disable react/prop-types */
import React, { createContext, useRef } from 'react';

export const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const dashboardReloadRef = useRef();

  return (
    <DashboardContext.Provider value={{ dashboardReloadRef }}>
      { children }
    </DashboardContext.Provider>
  );
};
