/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import {
  Box, Grid, Button, Fab, Container,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import { LoadingContext } from './context/LoadingContext';
import { ErrorContext } from 'stockpoint-common';
import { signOut } from 'firebase/auth';
import { auth } from '../api/fire';
import client from '../api';
import ProductCard from './ProductCard';
import ProductAddDialog from './ProductAddDialog';
import TagDialog from './TagDialog';
import { DashboardContext } from './DashboardContext';
import { generateQR, generatePDF } from '../utils/generate';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}));

const Dashboard = () => {
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { setError } = useContext(ErrorContext);

  const { dashboardReloadRef } = useContext(DashboardContext);

  const [products, setProducts] = useState(null);
  const [brands, setBrands] = useState(null);
  const [showLoadMore, setShowLoadMore] = useState(true);

  const [showTagDialog, setShowTagDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [showAddDialog, setShowAddDialog] = useState(false);

  const offsetRef = useRef(0);


  const loadBrands = async () => {
    try {
      setError(null);
      const resp = await client.get('/brands');
      setBrands(resp.data.data);
    } catch (err) {
      if (err.response?.status === 403) {
        setError('Your account is not authorized to access Stock Point Manager.');
        await signOut(auth);
      } else {
        setError(err.response?.data?.error || err.message);
      }
    }
  };

  const loadProducts = async (force = false) => {
    try {
      setError(null);
      setIsLoading(true);
      // Reset offset if forced reload
      if (force) {
        offsetRef.current = 0;
      }
      const params = { limit: 50, offset: offsetRef.current };
      const resp = await client.get('/products', { params });
      const items = resp.data.data;
      offsetRef.current += items.length;
      setShowLoadMore(items.length !== 0 && items.length === params.limit);
      // Append to products if exist
      if (!force && products) {
        setProducts([...products, ...items]);
      } else {
        setProducts(items);
      }
    } catch (err) {
      if (err.response?.status === 403) {
        setError('Your account is not authorized to access Stock Point Manager.');
        await signOut(auth);
      } else {
        setError(err.response?.data?.error || err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const addProduct = (product) => {
    let brand = brands.find(b => b.id == product.brand_id);
    product.brand = brand;
    delete product.brand_id;
    setProducts([product, ...products]);
  }

  const editProduct = (product) => {
    let brand = brands.find(b => b.id == product.brand_id);
    product.brand = brand;
    let productsCopy = [...products];
    let index = productsCopy.findIndex(p => p.id == product.id);
    productsCopy[index] = product;
    setProducts(productsCopy);
  }

  const updateImage = (pi) => {
    let productsCopy = [...products];
    let index = productsCopy.findIndex(p => p.id == pi.id);
    productsCopy[index].image_url = pi.image_url;
    setProducts(productsCopy);
  }


  const handlePrint = async (product) => {
    try {
      setIsLoading(true);
      // Generate QR
      const qrDataURL = await generateQR(product.id);
      // Generate PDF
      const getFilename = ({
        id, model, size, color,
      }) => [id, model, size, color].join('-');
      generatePDF({
        brand: product.brand,
        model: product.model,
        size: product.size,
        color: product.color,
        imageDataURL: qrDataURL,
        suggestedFilename: getFilename(product),
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    dashboardReloadRef.current = async () => loadProducts(true);
    await loadBrands();
    await loadProducts();
  }, []);

  const classes = useStyles();
  return (
    <Box sx={{ margin: '20px' }}>
      {showTagDialog && (
        <TagDialog
          isOpen={showTagDialog}
          product={selectedProduct}
          handleClose={() => {
            setShowTagDialog(false);
            setSelectedProduct(null);
          }}
          handlePrint={() => handlePrint(selectedProduct)}
        />
      )}

      {showAddDialog && (
        <ProductAddDialog
          isOpen={showAddDialog}
          editProduct={selectedProduct}
          onCreate={(p) => addProduct(p)}
          onUpdateImage={(pi) => updateImage(pi)}
          onUpdate={(p) => editProduct(p)}
          handleClose={() => {
            setShowAddDialog(false);
            setSelectedProduct(null);
          }}
        />
      )}

      {products && (
        <Grid container justifyContent="center" spacing={3}>
          {products.map((value) => (
            <Grid item key={value.id} style={{ display: 'flex' }}>
              <ProductCard
                product={value}
                onSelect={() => {
                  setSelectedProduct(value);
                  setShowAddDialog(true);
                }}
                showTag={() => {
                  setSelectedProduct(value);
                  setShowTagDialog(true);
                }}
                handlePrint={() => handlePrint(value)}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {!isLoading && showLoadMore && (
        <Container style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
          <Button
            variant="outlined"
            onClick={async () => loadProducts()}
          >
            Load more
          </Button>
        </Container>
      )}

      <Fab
        className={classes.fab}
        color="primary"
        aria-label="add"
        onClick={() => setShowAddDialog(true)}
      >
        <AddIcon />
      </Fab>
    </Box>
  );
};

export default Dashboard;
