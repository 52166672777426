/* eslint-disable no-shadow */
import React, { useContext, useState, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import { LoadingContext } from '../context/LoadingContext';
import { ErrorContext } from 'stockpoint-common';
import ReportTable from './ReportTable';
import client from '../../api';

const StockpointsReport = () => {
  const { setIsLoading } = useContext(LoadingContext);
  const { setError } = useContext(ErrorContext);

  const [data, setData] = useState([]);

  const loadData = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const resp = await client.get('/top-stockpoints');
      setData(resp.data.data);
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Stack spacing={2}>
      <Typography variant="h6">
        Top 10 Stockpoints
      </Typography>

      { data && (
        <ReportTable
          header={[
            'Stockpoint', 'Sold', 'Country', 'City',
          ]}
          items={data}
          itemToRow={(obj) => [
            obj.name, obj.prod_count, obj.country, obj.city,
          ]}
          keyAtIdx={(idx) => data[idx].id}
          pagination={false}
        />
      ) }
    </Stack>
  );
};

export default StockpointsReport;
