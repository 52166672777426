export const ANY = '*';
export const DASHBOARD = '/';

export const SIGN_IN = '/signin';
export const SIGN_UP = '/signup'
export const SIGN_OUT = '/signout';

export const STOCKPOINTS = '/stockpoints';
export const PROMO = '/promo';

export const REPORTS = '/reports';

export const REWARDS = '/rewards';
export const REWARD_CATEGORIES = '/reward-categories';

