import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import Tag from './Tag';

const TagDialog = ({
  isOpen, product, handleClose, handlePrint,
}) => {
  const title = [
    product.model,
    product.color,
    product.size,
  ].join(', ');

  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Tag product={product} />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            startIcon={<CloseOutlinedIcon />}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            color="primary"
            startIcon={<LocalPrintshopOutlinedIcon />}
            onClick={handlePrint}
          >
            Print
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

TagDialog.propTypes = {
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handlePrint: PropTypes.func.isRequired,
};

TagDialog.defaultProps = {
  isOpen: false,
};

export default TagDialog;
