import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button, Container, Stack, TextField } from '@mui/material';
import * as yup from 'yup';
import { useContext } from 'react';
import client from '../../api';
import { LoadingContext } from '../context/LoadingContext';
import { ErrorContext, Toast } from 'stockpoint-common';
import AutocompleteWithAdd from '../../controls/AutocompleteWithAdd';
import ImageDialog from '../ImageDialog';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';


const defaultImageUrl = 'https://firebasestorage.googleapis.com/v0/b/stockpoint-prod.appspot.com/o/reward-images%2Freward_default.jpg?alt=media&token=bcc83340-570d-4690-9c3b-ac0cb04a200d'

const validationSchema = yup.object({
    name: yup
        .string('Enter reward name')
        .required('Reward name is required'),
    star_value: yup
        .number('Star value must be a number')
        .positive('Enter only positive star value')
        .required('Star value is required'),
    category_name: yup
        .string('Select or add reward category')
        .required('Reward category is required'),
});


export const RewardUpsertForm = ({ rewardEdit, handleClose, setNeedUpdate }) => {
    const { setIsLoading } = useContext(LoadingContext);
    const { setError } = useContext(ErrorContext);
    const [submitData, setSubmitData] = useState(null);

    const [loadingCategories, setLoadingCategories] = useState(false);
    const [categories, setCategories] = useState([]);


    const [showImageDialog, setShowImageDialog] = useState(false);

    const loadCategories = async () => {
        try {
            setError(null);
            setLoadingCategories(true);
            const resp = await client.get('reward-categories');
            setCategories(resp.data.data);
        } catch (err) {
            setError(err.response?.data?.error || err.message);
        } finally {
            setLoadingCategories(false);
        }
    };

    useEffect(async () => {
        await loadCategories();
    }, []);

    const formik = useFormik({
        initialValues: {
            id: rewardEdit !== undefined ? rewardEdit.id : null,
            name: rewardEdit !== undefined ? rewardEdit.name : '',
            star_value: rewardEdit !== undefined ? rewardEdit.star_value : '',
            category_id: rewardEdit !== undefined ? rewardEdit.category_id : '',
            category_name: rewardEdit !== undefined ? rewardEdit.category_name : '',
            image_url: rewardEdit !== undefined ? rewardEdit.image_url : defaultImageUrl,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (values.category_id === null) delete values.category_id;
            if (rewardEdit !== undefined) {
                await handleEdit(values);
                handleClose(true);
            } else {
                setNeedUpdate(true);
                delete values.id;
                await handleCreate(values);
                values.name = '';
                values.star_value = '';
                formik.resetForm({ values: values })
            }
        },
    })


    const handleEdit = async (formValues) => {
        try {
            setIsLoading(true);
            const resp = await client.post('reward-edit', formValues);
            setSubmitData(resp);
        } catch (err) {
            setError(err.response.data.error);
        } finally {
            setIsLoading(false);
        }
    }


    const handleCreate = async (formValues) => {
        try {
            setIsLoading(true);
            const resp = await client.post('reward-add', formValues);
            setSubmitData(resp);
        } catch (err) {
            setError(err.response.data.error);
        } finally {
            setIsLoading(false);
        }
    }


    return (
        <>
            {showImageDialog && rewardEdit && (
                <ImageDialog
                    isOpen={showImageDialog}
                    imageName={rewardEdit.id}
                    imageURL={rewardEdit?.image_url}
                    handleClose={() => setShowImageDialog(false)}
                    updateImageURL={(fileUrl) => formik.setFieldValue('image_url', fileUrl, false)}
                    imagesFodler={"reward-images"}
                    imageSize={{ MAX_WIDTH: 170, MAX_HEIGHT: 170 }}
                />
            )}
            <Container maxWidth="sm"
                sx={{ textAlign: "center" }}>
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <TextField
                            id="name"
                            name="name"
                            placeholder="Reward name"
                            label='Name'
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <TextField
                            id="star_value"
                            name="star_value"
                            placeholder="Reward star value"
                            label='Stars'
                            value={formik.values.star_value}
                            onChange={formik.handleChange}
                            error={formik.touched.star_value && Boolean(formik.errors.star_value)}
                            helperText={formik.touched.star_value && formik.errors.star_value}
                        />
                        <AutocompleteWithAdd
                            label="Reward category"
                            value={formik.values.category_name}
                            setValue={(value) => {
                                formik.setFieldValue('category_name', value, true)
                                formik.setFieldValue('category_id', null, true)
                                formik.setTouched('category_name')
                            }}
                            options={categories}
                            optionToString={(option) => option?.name || ''}
                            optionKey={(option) => option.id ?? option}
                            optionChanged={(option) => {
                                formik.setFieldValue('category_id', option?.id, true);
                                formik.setTouched('category_name')
                            }}
                            loading={loadingCategories}
                            disabled={loadingCategories}
                            error={formik.touched.category_name && Boolean(formik.errors.category_name)}
                            helperText={formik.touched.category_name && formik.errors.category_name}
                        />

                        {rewardEdit && (<>
                            <img
                                src={formik.values.image_url}
                                loading="lazy"
                                style={{ objectFit: "contain", borderRadius: "5px", maxHeight: "70px", maxWidth: "100%", width: "auto" }}
                            />
                            <Button
                                color="primary"
                                startIcon={<PhotoOutlinedIcon />}
                                onClick={() => setShowImageDialog(true)}
                            >
                                Add Image
                            </Button>
                        </>
                        )}
                        <Button variant="contained" type="submit">Submit</Button>
                    </Stack>
                </form>
            </Container>
            <Toast
                isOpen={!!submitData}
                dismiss={() => setSubmitData(null)}
                message="Successful!"></Toast>
        </>
    )
};

