/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useState, useContext, useImperativeHandle, forwardRef, useRef,
} from 'react';
import {
  Stack, Divider, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  ref as fbRef, uploadString, getDownloadURL,
} from 'firebase/storage';
import { ErrorContext } from 'stockpoint-common';
import { LoadingContext } from './context/LoadingContext';
import { storage } from '../api/fire';
import resizeImage from '../utils/resizeImage';

const ImagePreview = ({ imageName, imageURL: _imageURL, handleSelected, updateImageURL, imagesFodler, imageSize }, ref) => {
  const { setIsLoading } = useContext(LoadingContext);
  const { setError } = useContext(ErrorContext);

  const [imageURL, setImageURL] = useState(_imageURL);
  const [imageDataURL, setImageDataURL] = useState(null);

  const inputRef = useRef();

  const handleImageSelected = async (file) => {
    const dataURL = await resizeImage(file, imageSize);
    setImageDataURL(dataURL);
    handleSelected();
  };

  const uploadFile = async (fileDataURL, name) => {
    const filePath = `${imagesFodler}/${name}`;
    const fileRef = fbRef(storage, filePath);
    let fileURL = null;
    try {
      setError(null);
      setIsLoading(true);
      await uploadString(fileRef, fileDataURL, 'data_url');
      fileURL = await getDownloadURL(fileRef);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
    return fileURL;
  };


  const uploadImage = async () => {
    const fileURL = await uploadFile(imageDataURL, imageName);
    await updateImageURL(fileURL);
    setImageURL(fileURL);
  };

  useImperativeHandle(ref, () => ({
    uploadImage,
    selectImage: async () => {
      inputRef.current.click();
    },
  }));

  const useStyles = makeStyles(() => createStyles({
    previewImg: {
      width: '350px',
      maxWidth: '100%',
      borderRadius: '7px',
    },
  }));

  const classes = useStyles();
  return (
    <>
      <input
        hidden
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={(e) => handleImageSelected(e.target.files[0])}
      />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        divider={<Divider orientation="vertical" flexItem />}
      >
        {!(imageURL || imageDataURL) && (
          <Typography variant="subtitle1">No image selected</Typography>
        )}

        {imageURL && (
          <Stack spacing={1}>
            {imageURL && imageDataURL && (
              <Typography variant="subtitle1">Old</Typography>
            )}
            <img
              className={classes.previewImg}
              src={imageURL}
              alt="Preview Old"
            />
          </Stack>
        )}
        {imageDataURL && (
          <Stack spacing={1}>
            {imageURL && imageDataURL && (
              <Typography variant="subtitle1">New</Typography>
            )}
            <img
              className={classes.previewImg}
              src={imageDataURL}
              alt="Preview New"
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default forwardRef(ImagePreview);
