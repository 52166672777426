/* eslint-disable new-cap */
import QRCode from 'qrcode';
import { jsPDF } from 'jspdf';
// Custom fonts
import PFBagueSansProRegular from '../fonts/PFBagueSansPro-Regular.ttf.b64';
import PFBagueSansProBold from '../fonts/PFBagueSansPro-Bold.ttf.b64';
import TrajanRegular from '../fonts/Trajan-Regular.ttf.b64';

const fonts = [
  {
    content: PFBagueSansProRegular,
    filename: 'PFBagueSansPro-Regular.otf',
    name: 'PFBagueSansPro-Regular',
  },
  {
    content: PFBagueSansProBold,
    filename: 'PFBagueSansPro-Bold.otf',
    name: 'PFBagueSansPro-Bold',
  },
  {
    content: TrajanRegular,
    filename: 'Trajan-Regular.ttf',
    name: 'Trajan-Regular',
  },
];

const generateQR = async (productId, width = 200, height = 200) => {
  const { REACT_APP_CLIENT_BASE_URL } = process.env;
  const dataURL = await QRCode.toDataURL(
    REACT_APP_CLIENT_BASE_URL + productId, {
    width,
    height,
    errorCorrectionLevel: 'M',
    margin: 0,
    scale: 1,
  },
  );
  return dataURL;
};

export { generateQR };

const getImageSize = (imageDataURL) => new Promise((resolve) => {
  const image = new Image();
  image.onload = () => resolve({ width: image.width, height: image.height });
  image.src = imageDataURL;
});

const loadFonts = async (doc) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const config of fonts) {
    /* eslint-disable no-await-in-loop */
    const font = await (await fetch(config.content)).text();
    doc.addFileToVFS(config.filename, font);
    doc.addFont(config.filename, config.name, 'normal');
  }
};

const getImageByUrl = (url, callback) => {
  var image = new Image();
  image.setAttribute('crossOrigin', 'anonymous'); //getting images from external domain

  image.onload = function () {
    var canvas = document.createElement('canvas');
    canvas.width = this.naturalWidth;
    canvas.height = this.naturalHeight;

    //next three lines for white background in case png has a transparent background
    var ctx = canvas.getContext('2d');
    ctx.fillStyle = '#fff';  /// set white fill style
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    canvas.getContext('2d').drawImage(this, 0, 0);

    callback(canvas.toDataURL('image/png'));
  };

  image.src = url;
}




const generatePDF = async ({
  brand,
  model, color, size,
  imageDataURL, filename,
}, saveToFile = false) => {
  const doc = new jsPDF();
  await loadFonts(doc);

  const width = doc.internal.pageSize.getWidth();

  const textFontSize = 50;
  const interItemOffset = 25;

  let yOffset = 42;


  if (brand.image_url !== null) {

    let img;
    getImageByUrl(brand.image_url, function (dataUri) {
      img = dataUri;
    });

    let scale = 0.04;
    let imageSize = await getImageSize(brand.image_url);
    console.log(imageSize);
    imageSize = { width: imageSize.width * scale, height: imageSize.height * scale };
    const xOffset = (width - imageSize.width) / 2;
    doc.addImage(img, 'png', xOffset + 20, 0, imageSize.width, imageSize.height);

  } else {
    doc.setFont(brand.alt_font ? 'Trajan-Regular' : 'PFBagueSansPro-Bold');
    doc.setFontSize(textFontSize + 10);
    doc.text(brand.name || '', width / 2, yOffset, { align: 'center' });
  }


  yOffset += interItemOffset;
  doc.setFont('PFBagueSansPro-Regular');
  doc.setFontSize(textFontSize - 10);
  doc.text('My name is', width / 2, yOffset, { align: 'center' });



  doc.setFont('PFBagueSansPro-Bold');
  doc.setFontSize(textFontSize);

  var splitTitle = doc.splitTextToSize(model, 190);
  var splitTitleDims = doc.getTextDimensions(splitTitle);

  yOffset += interItemOffset;
  doc.text(splitTitle || '', width / 2, yOffset, { align: 'center' });
  var modelNameOffset = splitTitleDims.h + 5;


  yOffset += modelNameOffset;
  doc.setFont('PFBagueSansPro-Regular');
  doc.setFontSize(textFontSize - 10);
  doc.text(color.toUpperCase(), width / 2, yOffset, { align: 'center' });

  yOffset += interItemOffset;
  doc.setFont('PFBagueSansPro-Bold');
  doc.setFontSize(textFontSize);
  doc.text(size.toUpperCase() || '', width / 2, yOffset, { align: 'center' });

  yOffset += 15;
  const scale = 0.5;
  let imageSize = await getImageSize(imageDataURL);
  imageSize = { width: imageSize.width * scale, height: imageSize.height * scale };
  const xOffset = (width - imageSize.width) / 2;
  doc.addImage(imageDataURL, xOffset, yOffset, imageSize.width, imageSize.height);

  if (saveToFile) {
    doc.save(filename);
  } else {
    doc.setProperties({ title: filename });
    const w = window.open((doc.output('bloburl', { filename })));
    w.print();
  }
};

export { generatePDF };
