import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { PromoUpsertForm } from './PromoUpsertForm';

const PromoUpsertDialog = (props) => {

    const title = props.promo === undefined ? "Add promo" : "Edit promo"
    const [needUpdate, setNeedUpdate] = useState(false)

    return (
        <>
            <Dialog
                open={props.isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingLeft: "10px"
                    }}>
                        <Typography variant='h6' style={{ fontWeight: "600" }}>{title}</Typography>
                        <IconButton onClick={() => props.handleClose(needUpdate)}>
                            <CloseIcon></CloseIcon>
                        </IconButton>
                    </Box>


                </DialogTitle>
                <DialogContent style={{ padding: "10px 10px" }}>
                    <PromoUpsertForm
                        handleClose={props.handleClose}
                        promoEdit={props.promo}
                        setNeedUpdate={setNeedUpdate}
                    >
                    </PromoUpsertForm>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default PromoUpsertDialog;
