/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useState, useContext, useImperativeHandle, forwardRef, useEffect,
} from 'react';
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { LoadingContext } from './context/LoadingContext';
import { ErrorContext, ProductStatus } from 'stockpoint-common';
import client from '../api';
import AutocompleteWithAdd from '../controls/AutocompleteWithAdd';
import AsyncAutocomplete from '../controls/AsyncAutocomplete';
import { getCurrentDateString } from '../utils';

const ProductForm = ({
  product, onCreate, onUpdate, onDelete,
}, ref) => {
  const { setIsLoading } = useContext(LoadingContext);
  const { setError } = useContext(ErrorContext);

  const [model, setModel] = useState(product?.model || '');
  const [color, setColor] = useState(product?.color || '');
  const [size, setSize] = useState(product?.size || '');
  const [prodDate, setProdDate] = useState(product?.prod_date || getCurrentDateString());
  const [status, setStatus] = useState(product?.status || ProductStatus.options[0].value);
  const [collection, setCollection] = useState(product?.collection?.name || '');
  const [imageUrl, setImageUrl] = useState(product?.image_url);
  const [productId, setProductId] = useState(product?.id);
  const [collectionId, setCollectionId] = useState(product?.collection?.id);

  const [brandId, setBrandId] = useState(product?.collection?.brand_id ?? 1);

  const [loadingColls, setLoadingColls] = useState(false);
  const [collections, setCollections] = useState([]);

  const loadCollections = async () => {
    try {
      setError(null);
      setLoadingColls(true);
      const resp = await client.get('collections');
      setCollections(resp.data.data);
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    } finally {
      setLoadingColls(false);
    }
  };

  useEffect(async () => {
    await loadCollections();
  }, []);

  const handleSubmit = async (e) => {
    // 'event' is null when called from outside
    e?.preventDefault();

    try {
      setError(null);
      setIsLoading(true);
      let params = {
        model,
        color,
        size,
        prod_date: prodDate,
        status,
      };
      if (imageUrl) {
        params = { ...params, image_url: imageUrl };
      }
      // Append `collectionId` if not null
      if (collectionId) {
        params = { ...params, collection_id: collectionId };
      } else {
        params = { ...params, collection: { name: collection, brand_id: brandId } };
      }

      let productObj = {
        model: model,
        color: color,
        size: size,
        prod_date: prodDate,
        status: status,
        collection: {
          name: collection,
          id: collectionId
        },
        brand_id: brandId,
        image_url: imageUrl
      }
      console.log(productObj);

      if (productId) {
        // Update exisitng product
        const resp = await client.post(`/product/${productId}`, params);
        productObj.id = productId;
        productObj.collection.id = resp.data.data.collection_id;
        onUpdate(productObj);
      } else {
        // Create new product
        const resp = await client.post('/product', params);
        setProductId(resp.data.data.id);
        productObj.id = resp.data.data.id;
        productObj.collection.id = resp.data.data.collection_id;
        onCreate(productObj);
      }
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setError(null);
      setIsLoading(true);
      if (!productId) {
        throw new Error('Missing productId');
      }
      await client.delete(`/product/${productId}`);
      if (onDelete !== undefined) onDelete();
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    handleSubmit,
    handleDelete,
  }));

  return (
    <>
      <form onSubmit={handleSubmit} style={{ width: '400px', maxWidth: '100%' }}>
        <Stack spacing={2} style={{ margin: '5px 0' }}>
          {product?.id && (
            <TextField
              label="Id"
              value={product?.id}
              fullWidth
              InputProps={{ readOnly: true, disableUnderline: true }}
              variant="standard"
            />
          )}
          <AsyncAutocomplete
            label="Model"
            value={model}
            setValue={setModel}
            optionToString={(option) => (option?.model || '')}
            optionKey={(option) => option.id}
            optionLabel={(option) => {
              const title = [
                option?.model || '',
                option?.color || '',
                option?.size || '',
              ].filter((s) => s.length > 0).join(', ');
              return title || '';
            }}
            fetchOptions={async (query) => {
              const params = { q: `model:${query}` };
              const resp = await client.get('/products', { params });
              return resp.data.data;
            }}
            onOptionClick={(option) => {
              if (option) {
                console.log(option);
                setColor(option.color || '');
                setSize(option.size || '');
                setCollection(option.collection.name || '');
                setCollectionId(option.collection.id || '');
                setBrandId(option.collection.brand_id || '');
                setImageUrl(option.image_url);
              }
            }}
          />
          <TextField
            label="Color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
            fullWidth
            variant="outlined"
          />
          <TextField
            label="Size"
            value={size}
            onChange={(e) => setSize(e.target.value)}
            fullWidth
            variant="outlined"
          />
          <TextField
            label="ProdDate"
            type="date"
            value={prodDate}
            onChange={(e) => setProdDate(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
          <AutocompleteWithAdd
            label="Collection"
            value={collection}
            setValue={(value) => {
              setCollectionId(null);
              setCollection(value);
            }}
            options={collections}
            optionToString={(option) => option?.name || ''}
            optionKey={(option) => option.id ?? option}
            optionChanged={(option) => {
              setCollectionId(option?.id)
              setBrandId(option?.brand_id)
            }}
            loading={loadingColls}
            disabled={loadingColls}
          />
          {collectionId === null && (
            <FormControl fullWidth>
              <InputLabel>Brand</InputLabel>
              <Select
                value={brandId}
                label="Brand"
                onChange={(event) => setBrandId(event.target.value)}
              >
                <MenuItem value={1}>Milla Nova</MenuItem>
                <MenuItem value={2}>White&Lance</MenuItem>
                <MenuItem value={3}>Milla</MenuItem>
              </Select>
            </FormControl>
          )}
          <TextField
            select
            label="Status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            fullWidth
            variant="outlined"
            SelectProps={{ native: true }}
          >
            {ProductStatus.options.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.name}
              </option>
            ))}
          </TextField>
        </Stack>
      </form>
    </>
  );
};

export default forwardRef(ProductForm);
