/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Paper, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { signInWithPopup } from 'firebase/auth';
import { LoadingContext } from './context/LoadingContext';
import {
  ErrorContext,
  DialogOption, confirm,
  MillaLogo, GoogleButton, FacebookButton, AuthContext, AppleButton,
} from 'stockpoint-common';
import {
  auth, googleProvider, facebookProvider,appleProvider,
  handleAccountAlreadyExistsError,
} from '../api/fire';
import client from '../api';
import * as path from './routing/path'


const useStyles = makeStyles(() => ({
  paperFooterContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: '40px 20px',
    // background: 'blue',
  },
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    // background: 'red',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '60px 0px',
    width: '100%',
    maxWidth: '500px',
    // background: 'brown',
  },
}));

const Login = () => {
  const { setIsLoading } = useContext(LoadingContext);
  const { setError } = useContext(ErrorContext);

  const history = useHistory();

  const [success, setSuccess] = useState(false);
  const { currentUser } = useContext(AuthContext);



  useEffect(() => {
    if (currentUser) {
      history.replace(path.DASHBOARD)
    }
  }, [])

  const handleProviderSignIn = async (provider) => {
    setIsLoading(true);
    setError(null);
    setSuccess(false);
    try {
      const result = await signInWithPopup(auth, provider);
      await client.get('profile');
      const { user } = result;
      setSuccess(user);
    } catch (err) {
      if (err.code !== 'auth/account-exists-with-different-credential') {
        setError(err.message);
        return;
      }
      // Handle Firebase accounts linking
      const user = await handleAccountAlreadyExistsError(err, async () => {
        // Hide loading indicator while presenting ConfirmDialog
        setIsLoading(false);
        const result = await confirm({
          title: 'Account already exists',
          content: "You've already signed-in using different provider, accounts will be merged.",
          options: [DialogOption.ok],
        });
        setIsLoading(true);
        return result === 'ok';
      });
      setSuccess(!!user);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      history.replace(path.DASHBOARD);
    }
  }, [success]);

  const classes = useStyles();
  return (
    <>
      <Box className={classes.paperFooterContainer}>
        <Box className={classes.paperContainer}>
          <Paper className={classes.paper}>
            <MillaLogo subtitle="Stock Point Manager" />
            <Stack
              spacing={2}
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: '90px' }}
            >
              <AppleButton onClick={() => handleProviderSignIn(appleProvider)} />
              {/* <FacebookButton onClick={() => handleProviderSignIn(facebookProvider)} /> */}
              <GoogleButton onClick={() => handleProviderSignIn(googleProvider)} />
            </Stack>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default Login;
