/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import { useDebouncedCallback } from 'use-debounce';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  margin: theme.spacing(1, 1, 1, 0),
  width: '100%',
  maxWidth: '500px',
  [theme.breakpoints.up('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'block',
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
  flexGrow: '1',
}));

const StyledInput = styled(Autocomplete)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-root': {
    color: 'inherit',
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: '10px',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function SearchBar({
  value, setValue,
  optionToString, optionKey, optionLabel,
  fetchOptions, onOptionClick,
}) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const onInputChange = async (newValue) => {
    if (newValue && newValue.length > 0) {
      try {
        setLoading(true);
        const results = await fetchOptions(newValue);
        setOptions(results);
      } catch (err) {
        setOptions([]);
      } finally {
        setLoading(false);
      }
    }
  };

  const debouncedInputChange = useDebouncedCallback(
    (value) => onInputChange(value),
    500,
  );

  return (
    <Search>
      <StyledInput
        freeSolo
        value={value}
        onChange={(e, newValue) => {
          if (typeof newValue === 'string') {
            setValue(newValue);
          } else {
            setValue(optionToString(newValue));
            onOptionClick(newValue);
          }
        }}
        filterOptions={(x) => x}
        isOptionEqualToValue={(option, value) => optionKey(option) === optionKey(value)}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Regular option
          return optionLabel(option);
        }}
        options={options}
        loading={loading}
        renderOption={(props, option) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...props} key={optionKey(option)}>{optionLabel(option)}</li>
        )}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            placeholder="Search…"
            onChange={(e) => debouncedInputChange(e.target.value)}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <SearchIcon />
              ),
              endAdornment: (
                <>
                  {loading && <CircularProgress color="inherit" size={20} />}
                </>
              ),
            }}
          />
        )}
        fullWidth
      />
    </Search>
  );
}
