import React, { useState } from 'react'
import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import PromoProducts from './PromoProducts';
import PromoProductsAvailable from './PromoProductsAvailable';


export default function PromoProductsDialog({ isOpen, promo, handleClose }) {
    const [needReload, setNeedReload] = useState(false);
    return (
        <>
            <Dialog
                maxWidth={"95%"}
                fullWidth={true}
                open={isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingLeft: "10px"
                    }}>
                        <Typography variant='h6' style={{ fontWeight: "600" }}>Promo products</Typography>
                        <IconButton onClick={() => handleClose()}>
                            <CloseIcon></CloseIcon>
                        </IconButton>
                    </Box>


                </DialogTitle>
                <DialogContent style={{ padding: "10px 10px" }}>
                    <Stack direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <PromoProductsAvailable promoId={promo.id} needReload={needReload} setNeedReload={setNeedReload}></PromoProductsAvailable>
                        <PromoProducts promoId={promo.id} needReload={needReload} setNeedReload={setNeedReload}></PromoProducts>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    )
}
