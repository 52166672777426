import axios from 'axios';
import { getIdToken } from 'firebase/auth';
import { auth } from './fire';

const { REACT_APP_API_ENDPOINT } = process.env;

const client = axios.create({ baseURL: REACT_APP_API_ENDPOINT });

client.interceptors.request.use(async (config) => {
  const token = await getIdToken(auth.currentUser);
  // eslint-disable-next-line no-param-reassign
  config.headers['x-access-token'] = token;
  return config;
});

export default client;
