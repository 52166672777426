import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { RewardCategoriesUpsertForm } from './RewardCategoriesUpsertForm';

const RewardCategoriesUpsertDialog = (props) => {

    const title = props.reward === undefined ? "Add category" : "Edit category"
    const [needUpdate, setNeedUpdate] = useState(false)

    return (
        <>
            <Dialog
                open={props.isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingLeft: "10px"
                    }}>
                        <Typography variant='h6' style={{ fontWeight: "600" }}>{title}</Typography>
                        <IconButton onClick={() => props.handleClose(needUpdate)}>
                            <CloseIcon></CloseIcon>
                        </IconButton>
                    </Box>


                </DialogTitle>
                <DialogContent style={{ padding: "10px 10px" }}>
                    <RewardCategoriesUpsertForm
                        handleClose={props.handleClose}
                        categoryEdit={props.category}
                        setNeedUpdate={setNeedUpdate}
                        maxSortId={props.maxSortId}
                    >
                    </RewardCategoriesUpsertForm>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default RewardCategoriesUpsertDialog;
