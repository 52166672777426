const resizeDataURL = async (dataURL, MAX_WIDTH, MAX_HEIGHT) => {
  // Load image
  const img = document.createElement('img');
  img.src = dataURL;
  const imgLoaded = () => new Promise((resolve, reject) => {
    img.onload = (e) => resolve(e);
    img.onerror = (err) => reject(err);
  });
  await imgLoaded();

  // Resize image
  let { width, height } = img;
  if (width > height) {
    if (width > MAX_WIDTH) {
      height *= MAX_WIDTH / width;
      width = MAX_WIDTH;
    }
  } else if (height > MAX_HEIGHT) {
    width *= MAX_HEIGHT / height;
    height = MAX_HEIGHT;
  }

  // Create canvas and draw resized image
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(img, 0, 0, width, height);
  return canvas.toDataURL();
};

const resizeImage = (file, imageSize) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    resolve(resizeDataURL(e.target.result, imageSize.MAX_WIDTH, imageSize.MAX_HEIGHT));
  };
  reader.onerror = (err) => reject(err);
  reader.readAsDataURL(file);
});

export default resizeImage;
