import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import { visuallyHidden } from '@mui/utils';
import { Checkbox, Container, Fab, FormControl, FormControlLabel, FormGroup, InputAdornment, InputLabel, LinearProgress, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingContext } from '../context/LoadingContext';
import { ErrorContext, Toast } from 'stockpoint-common';
import client from '../../api';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import RewardUpsertDialog from './RewardUpsertDialog';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ConfirmationDialog from '../custom/ConfirmationDialog';



const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    }
  }
};

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}));

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    isSortable: true,
  },
  {
    id: 'star_value',
    numeric: false,
    disablePadding: false,
    label: 'Stars',
    isSortable: true,
  },
  {
    id: 'category_name',
    numeric: false,
    disablePadding: false,
    label: 'Reward category',
    isSortable: true,
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: '',
    isSortable: false,
    filterAvailable: false,
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: "700", backgroundColor: "#f5f5f5" }}
          >
            {headCell.isSortable && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};


export default function Rewards() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);

  const rowsPerPageArray = [10, 15, 25, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageArray[2]);


  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const { setError } = useContext(ErrorContext);


  const [showImages, setShowImages] = useState(true);
  const [showUpsertDialog, setShowUpsertDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [rewardSelected, setRewardSelected] = useState(undefined);
  const [submitData, setSubmitData] = useState(null);

  const [rewards, setRewards] = useState([])

  const [categories, setCategories] = useState([{ id: 0, name: "All categories" }]);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);


  const loadRewards = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const params = { limit: rowsPerPage, offset: page * rowsPerPage, category_id: selectedCategory.id > 0 ? selectedCategory.id : null };
      const resp = await client.post('/rewards-page', { order: { orderBy, direction: order }, ...params });
      const items = resp.data.data;
      setRewards(items);

    } catch (err) {
      if (err.response?.status === 403) {
        setError('Your account is not authorized to access Stock Point Manager.');
        await signOut(auth);
      } else {
        setError(err.response?.data?.error || err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const deleteReward = async () => {
    try {
      setIsLoading(true);
      const resp = await client.post('reward-delete', { id: rewardSelected.id });
      setSubmitData(resp);
    } catch (err) {
      console.log(err.response.data.error);
      setError(err.response.data.error);
    } finally {
      setIsLoading(false);
    }
  }

  const loadCategories = async () => {
    let categs = [{ id: 0, name: "All categories" }]
    try {
      const resp = await client.get('reward-categories');
      setCategories(categs.concat(resp.data.data));
    } catch (err) {
      setCategories(categs);
    }
  };

  useEffect(async () => {
    await loadCategories();
  }, []);


  useEffect(async () => {
    await loadRewards();
  }, [order, orderBy, page, rowsPerPage, selectedCategory])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isNextPageAvailable = () => {
    return rewards.length < rowsPerPage;
  };

  const handleCloseDialog = async (needRelaod) => {
    if (needRelaod) {
      await loadRewards();
      await loadCategories();
    }
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, rowsPerPage - rewards.length) : 0;


  const classes = useStyles();

  return (
    <>
      {showDeleteDialog && (
        <ConfirmationDialog
          isOpen={showDeleteDialog}
          handleClose={async (res) => {
            setShowDeleteDialog(false);
            if (res) await deleteReward();
            await handleCloseDialog(res);
          }}>
          <Typography>Are you sure you want delete {rewardSelected.name}?</Typography>
        </ConfirmationDialog>
      )}
      {showUpsertDialog && (
        <RewardUpsertDialog
          isOpen={showUpsertDialog}
          reward={rewardSelected}
          handleClose={async (res) => {
            setShowUpsertDialog(false);
            await handleCloseDialog(res);
          }}
        />
      )}
      <Container style={{ marginTop: "8px" }}>
        <Stack sx={{ mt: 2 }} direction="row">
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel id="label1">Reward category</InputLabel>
            <Select
              value={selectedCategory}
              labelId="label1"
              onChange={(e) => setSelectedCategory(e.target.value)}
              variant="standard"
              size="small"
              renderValue={(category) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  <FilterAltIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />{category.name}
                </Box>
              )}
              MenuProps={MenuProps}>
              {categories.map((c) => (
                <MenuItem key={c.id} value={c}>{c.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel sx={{ pt: 2, pl: 3 }} control={<Checkbox checked={showImages} onChange={() => { setShowImages(!showImages) }} />} label="Show reward images" />
        </Stack>
        <Paper sx={{ marginTop: 3 }}  >
          <TableContainer sx={{ maxHeight: 650 }} style={{ minWidth: 750 }}>
            <Scrollbars autoHeight={true} autoHeightMax={650}>
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {rewards.map((r, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Stack direction="row" style={{ display: "flex", alignItems: "center" }}>
                            {showImages && (
                              <Container style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent:"center",
                                height: "85px",
                                width: "85px",
                                padding: "0px",
                                margin: "0px 8px 0px 0px"
                              }}>
                                <img
                                  src={r.image_url}
                                  loading="lazy"
                                  style={{ objectFit: "contain", borderRadius: "5px", height: "auto", maxHeight: "85px", maxWidth: "85px", width: "auto" }}
                                />
                              </Container>
                            )}
                            {r.name}
                          </Stack>
                        </TableCell>
                        <TableCell>{r.star_value}</TableCell>
                        <TableCell>{r.category_name}</TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            justifyContent="flex-start">
                            <IconButton aria-label="edit" size='small'
                              onClick={() => {
                                setRewardSelected(r);
                                setShowUpsertDialog(true);
                              }}>
                              <EditIcon />
                            </IconButton>
                            <IconButton aria-label="delete" size='small'
                              onClick={() => {
                                setRewardSelected(r);
                                setShowDeleteDialog(true);
                              }}>
                              <DeleteIcon />
                            </IconButton>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 47 : 67) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={4} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Scrollbars>
          </TableContainer>
        </Paper>
        <TablePagination
          style={{ paddingRight: "90px" }}
          rowsPerPageOptions={rowsPerPageArray}
          component="div"
          count={-1}
          rowsPerPage={rowsPerPage}
          page={page}
          showFirstButton={true}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{ disabled: isNextPageAvailable() }}
        />
      </Container>
      <Fab
        className={classes.fab}
        color="primary"
        aria-label="add"
        onClick={() => {
          setRewardSelected(undefined);
          setShowUpsertDialog(true);
        }}
      >
        <AddIcon />
      </Fab>
      <Toast
        isOpen={!!submitData}
        dismiss={() => setSubmitData(null)}
        message="Successful!"></Toast>
    </>

  );
}
