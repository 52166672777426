import React, { useState } from 'react'
import { Collapse, Grow, IconButton, Stack, Tooltip } from '@mui/material';
import FilterField from './FilterField';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';



export default function FilterPanelCollapsible({ isLoaded, headCells, handleFilterFieldChange, applyFilters, visibleDefault = false}) {
    const [filtersVisible, setFiltersVisible] = useState(visibleDefault);

    return (<>
        <Tooltip title="Filters" placement="right">
            <IconButton onClick={() => setFiltersVisible(!filtersVisible)}>
                <FilterListIcon />
            </IconButton>
        </Tooltip>
        <Collapse in={filtersVisible && isLoaded}>
            <Grow in={filtersVisible && isLoaded} style={{
                transformOrigin: '0 0 0'
            }} {...filtersVisible ? {
                timeout: 500
            } : {}}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={4} style={{
                    paddingLeft: "40px"
                }}>
                    {headCells.map((h, idx) => h.filterAvailable &&
                        <FilterField key={idx} h={h} handleFilterFieldChange={handleFilterFieldChange} applyFilters={applyFilters} />)}
                    <Tooltip title="Apply filters" placement="top">
                        <IconButton onClick={() => applyFilters()}>
                            <SearchIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Grow>
        </Collapse>
    </>);
}