import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, DialogActions, Button, Grid,
} from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { ErrorContext, isDevelopment } from 'stockpoint-common';
import ProductForm from './ProductForm';
import ImageDialog from './ImageDialog';
import { LoadingContext } from './context/LoadingContext';
import client from '../api';


const ProductAddDialog = ({
  isOpen, title, editProduct, onCreate, onUpdate,onUpdateImage, handleClose,
}) => {
  const formRef = useRef();

  const [productId, setProductId] = useState(editProduct?.id);
  const [showImageDialog, setShowImageDialog] = useState(false);
  const { setIsLoading } = useContext(LoadingContext);
  const { setError } = useContext(ErrorContext);


  const updateImageURL = async (fileURL) => {
    try {
      setError(null);
      setIsLoading(true);
      const params = {
        image_url: fileURL,
      };
      await client.post(`/product/${productId}`, params);
      let epi = {
        id: productId,
        image_url: fileURL
      }
      onUpdateImage(epi);
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {productId && showImageDialog && (
        <ImageDialog
          isOpen={showImageDialog}
          imageName={productId}
          imageURL={editProduct?.image_url}
          handleClose={() => setShowImageDialog(false)}
          updateImageURL={async (fileUrl) => await updateImageURL(fileUrl)}
        />
      )}

      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{editProduct ? 'Edit product' : title}</DialogTitle>
        <DialogContent>
          <ProductForm
            ref={formRef}
            product={editProduct}
            onUpdate={onUpdate}
            onCreate={onCreate}
            onDelete={onUpdate}
          />
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={isDevelopment ? 'space-between' : 'flex-end'}>
            {isDevelopment && (
              <Grid item>
                {productId && (
                  <Button
                    color="secondary"
                    startIcon={<DeleteOutlinedIcon />}
                    onClick={() => {
                      formRef.current.handleDelete();
                      handleClose();
                    }}
                  >
                    Delete
                  </Button>
                )}
              </Grid>
            )}
            <Grid item>
              <Button
                color="primary"
                startIcon={<CloseOutlinedIcon />}
                onClick={handleClose}
              >
                {productId ? 'Close' : 'Cancel'}
              </Button>
              {productId && (
                <Button
                  color="primary"
                  startIcon={<PhotoOutlinedIcon />}
                  onClick={() => setShowImageDialog(true)}
                >
                  Add Image
                </Button>
              )}
              <Button
                color="primary"
                startIcon={<DoneOutlinedIcon />}
                onClick={() => formRef.current.handleSubmit()}
              >
                {productId ? 'Update' : 'Submit'}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

ProductAddDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdateImage: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  editProduct: PropTypes.object,
};

ProductAddDialog.defaultProps = {
  isOpen: false,
  title: 'Add Product',
  editProduct: null,
};

export default ProductAddDialog;
