/* eslint-disable no-shadow */
import React from 'react';
import { Stack, Divider } from '@mui/material';
import ProductsReport from './ProductsReport';
import ProfilesReport from './ProfilesReport';
import StockpointsReport from './StockpointsReport';

const Reports = () => (
  <Stack spacing={4} sx={{ padding: '30px' }}>
    <ProfilesReport />
    <Divider />
    <StockpointsReport />
    <Divider />
    <ProductsReport />
  </Stack>
);

export default Reports;
