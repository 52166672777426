import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { RewardUpsertForm } from './RewardUpsertForm';


const RewardUpsertDialog = ({ isOpen, reward, handleClose }) => {


    const title = reward === undefined ? "Add reward" : "Edit reward"
    const [needUpdate, setNeedUpdate] = useState(false)

    return (
        <>
            <Dialog
                open={isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingLeft: "10px"
                    }}>
                        <Typography variant='h6' style={{ fontWeight: "600" }}>{title}</Typography>
                        <IconButton onClick={() => handleClose(needUpdate)}>
                            <CloseIcon></CloseIcon>
                        </IconButton>
                    </Box>


                </DialogTitle>
                <DialogContent style={{ padding: "10px 10px" }}>
                    <RewardUpsertForm
                        handleClose={handleClose}
                        rewardEdit={reward}
                        setNeedUpdate={setNeedUpdate}
                    >
                    </RewardUpsertForm>                 
                </DialogContent>
            </Dialog>
        </>
    );
};

export default RewardUpsertDialog;
