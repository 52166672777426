import React, { useState, useRef } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltIcon from '@mui/icons-material/FilterAlt';



export default function FilterField({ h, handleFilterFieldChange, applyFilters }) {
    const inputRef = useRef();
    const [clearVisible, setClearVisible] = useState(false)

    const handleChange = (name, value) => {
        handleFilterFieldChange(name, value)
        setClearVisible(inputRef.current.value.length > 0);
    }

    const clearFilter = (name) => {
        inputRef.current.value = '';
        setClearVisible(false);
        handleFilterFieldChange(name, '')
        applyFilters();
    }

    return (<TextField
        id="standard-basic"
        label={h.filterLabel ?? h.label}
        variant="standard"
        size="small"
        style={{ maxWidth: "250px" }}
        onChange={e => handleChange(h.id, e.target.value)}
        onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              applyFilters()
              ev.preventDefault();
            }
          }}
        inputRef={inputRef}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                <FilterAltIcon />
            </InputAdornment>,
            endAdornment: (clearVisible && (<InputAdornment position="end">
                <IconButton onClick={() => clearFilter(h.id)}>
                    <ClearIcon />
                </IconButton>
            </InputAdornment>))
        }} />);
}