import React, { useEffect, useState } from 'react';
import client from '../../api';


export const PromoBaseContext = React.createContext();

export const PromoBaseProvider = ({ children }) => {
    const [promoBaseId, setPromoBaseId] = useState();

    const loadPromoBaseId = async () => {
        try {
            const resp = await client.get('/promo-base-id');
            const id = resp.data.data;
            setPromoBaseId(id);
        } catch (err) {
            setPromoBaseId(1);
        }
    };

    useEffect(async () => {
        await loadPromoBaseId()
    }, []);


    return (
        <PromoBaseContext.Provider value={{ promoBaseId }}>
            {children}
        </PromoBaseContext.Provider>
    );
};
