import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Button, Container, Stack, TextField } from '@mui/material';
import * as yup from 'yup';
import { useContext } from 'react';
import client from '../../api';
import { LoadingContext } from '../context/LoadingContext';
import { ErrorContext, Toast } from 'stockpoint-common';





const validationSchema = yup.object({
    name: yup
        .string('Enter promo name')
        .required('Promo name is required'),
    star_value: yup
        .number('Star value must be a number')
        .positive('Enter only positive star value')
        .required('Promo star value is required')
});


export const PromoUpsertForm = (props) => {

    const { setIsLoading } = useContext(LoadingContext);
    const { setError } = useContext(ErrorContext);
    const [submitData, setSubmitData] = useState(null);

    const formik = useFormik({
        initialValues: {
            id: props.promoEdit !== undefined ? props.promoEdit.id : null,
            name: props.promoEdit !== undefined ? props.promoEdit.name : '',
            star_value: props.promoEdit !== undefined ? props.promoEdit.star_value : '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (props.promoEdit !== undefined) {
                await handleEdit(values);
                props.handleClose(true);
            } else {
                props.setNeedUpdate(true);
                delete values.id;
                await handleCreate(values);
                values.name = '';
                formik.resetForm({ values: values })
            }
        },
    })


    const handleEdit = async (formValues) => {
        try {
            setIsLoading(true);
            const resp = await client.post('promo-edit', formValues);
            setSubmitData(resp);
        } catch (err) {
            setError(err.response.data.error);
        } finally {
            setIsLoading(false);
        }
    }


    const handleCreate = async (formValues) => {
        try {
            setIsLoading(true);
            const resp = await client.post('promo-add', formValues);
            setSubmitData(resp);
        } catch (err) {
            setError(err.response.data.error);
        } finally {
            setIsLoading(false);
        }
    }


    return (
        <>
            <Container maxWidth="sm"
                sx={{ textAlign: "center" }}>
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <TextField
                            id="name"
                            name="name"
                            placeholder="Promo name"
                            label='Name'
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <TextField
                            id="star_value"
                            name="star_value"
                            placeholder="Promo stars"
                            label='Promo stars'
                            value={formik.values.star_value}
                            onChange={formik.handleChange}
                            error={formik.touched.star_value && Boolean(formik.errors.star_value)}
                            helperText={formik.touched.star_value && formik.errors.star_value}
                        />                   
                        <Button variant="contained" type="submit">Submit</Button>
                    </Stack>
                </form>
            </Container>
            <Toast
                isOpen={!!submitData}
                dismiss={() => setSubmitData(null)}
                message="Successful!"></Toast>
        </>
    )
};

