import React, { useState, useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  AppBar, Toolbar, Button, Box, Popover, Menu, MenuItem, Stack,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { signOut } from 'firebase/auth';
import { AuthContext } from 'stockpoint-common';
import ProductAddDialog from './ProductAddDialog';
import SearchBar from '../controls/SearchBar';
import { auth } from '../api/fire';
import client from '../api';
import { DashboardContext } from './DashboardContext';
import * as path from './routing/path'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



const useStyles = makeStyles(() => ({
  button: {
    '&:hover': {
      backgroundColor: '#4a4a4a75',
    },
  },
  text_underline: {
    textDecoration: "underline"
  }
}))

const Navigation = () => {
  const { currentUser } = useContext(AuthContext);
  const { dashboardReloadRef } = useContext(DashboardContext);

  const [value, setValue] = useState('');
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const classes = useStyles()





  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleSignOut = async () => {
    await signOut(auth);
  };


  return (
    <>
      {/* TODO: Refactor out ProductAddDialog from Navigation */}
      {showAddDialog && (
        <ProductAddDialog
          isOpen={showAddDialog}
          editProduct={selectedProduct}
          onUpdate={dashboardReloadRef.current}
          handleClose={() => {
            setShowAddDialog(false);
            setSelectedProduct(null);
          }}
        />
      )}
      {currentUser && (
        <AppBar position="fixed" >
          <Toolbar variant="dense" style={{ minHeight: '59px' }}>
            <Button
              className={classes.button + `${location.pathname === path.DASHBOARD ? ' ' + classes.text_underline : ''}`}
              color="inherit"
              component={RouterLink}
              to={path.DASHBOARD}
            >
              Dashboard
            </Button>
            <Button
              className={classes.button + `${location.pathname === path.REPORTS ? ' ' + classes.text_underline : ''}`}
              color="inherit"
              component={RouterLink}
              to={path.REPORTS}
            >
              Reports
            </Button>
            <Button
              className={classes.button + `${location.pathname === path.STOCKPOINTS ? ' ' + classes.text_underline : ''}`}
              color="inherit"
              component={RouterLink}
              to={path.STOCKPOINTS}
            >
              Stockpoints
            </Button>
            <Button
              className={classes.button + `${location.pathname === path.PROMO ? ' ' + classes.text_underline : ''}`}
              color="inherit"
              component={RouterLink}
              to={path.PROMO}
            >
              Promo
            </Button>
            <Button
              id="basic-button"
              className={classes.button + `${location.pathname === path.REWARDS || location.pathname === path.REWARD_CATEGORIES ? ' ' + classes.text_underline : ''}`}
              color="inherit"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Rewards
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                onClick={handleClose}
                component={RouterLink}
                to={path.REWARDS}>
                Rewards
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                component={RouterLink}
                to={path.REWARD_CATEGORIES}>
                Reward categories
              </MenuItem>
            </Menu>

            <Box sx={{ flexGrow: '1' }} />
            {location.pathname === path.DASHBOARD && (
              <SearchBar
                value={value}
                setValue={setValue}
                optionToString={(option) => (option?.model || '')}
                optionKey={(option) => option.id}
                optionLabel={(option) => {
                  const title = [
                    option?.model || '',
                    option?.color || '',
                    option?.size || '',
                    option?.prod_date || '',
                  ].filter((s) => s.length > 0).join(', ');
                  return title || '';
                }}
                fetchOptions={async (query) => {
                  const params = { q: `model:${query}`, limit: 20 };
                  const resp = await client.get('/products', { params });
                  return resp.data.data;
                }}
                onOptionClick={(option) => {
                  if (option) {
                    setSelectedProduct(option);
                    setShowAddDialog(true);
                  }
                }}
              />
            )}
            <Button
              className={classes.button}
              color="inherit"
              onClick={handleSignOut}
            >
              Sign-out
            </Button>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default Navigation;
