// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider,
  fetchSignInMethodsForEmail, signInWithPopup, linkWithCredential,
} from 'firebase/auth';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const analytics = getAnalytics(app);
const storage = getStorage();

export {
  app, auth, storage, analytics,
};

// Auth providers
const googleProvider = (() => {
  const provider = new GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/userinfo.email');
  return provider;
})();

const facebookProvider = (() => {
  const provider = new FacebookAuthProvider();
  provider.addScope('email');
  return provider;
})();

const appleProvider = (() => {
  const provider = new OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');
  return provider;
})();

// Helpers
const credentialFromAuthError = (error) => OAuthProvider.credentialFromError(error);

const getProviderForId = (id) => {
  switch (id) {
    case 'google.com':
      return googleProvider;
    case 'facebook.com':
      return facebookProvider;
    case 'apple.com':
        return appleProvider;
    default:
      return null;
  }
};

const handleAccountAlreadyExistsError = async (authError, confirmHandler) => {
  const pendingCred = credentialFromAuthError(authError);
  const { email } = authError.customData;
  const methods = await fetchSignInMethodsForEmail(auth, email);
  const provider = getProviderForId(methods[0]);
  // confirmHandler should trigger user input
  const confirmResult = await confirmHandler();
  if (confirmResult) {
    const result = await signInWithPopup(auth, provider);
    const { user } = result;
    return linkWithCredential(user, pendingCred);
  }
  return null;
};

export {
  googleProvider, facebookProvider,appleProvider,
  handleAccountAlreadyExistsError,
};
