/* eslint-disable import/prefer-default-export */
const readFileAsDataURL = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event) => resolve(event.target.result);
  reader.onerror = (err) => reject(err);
  reader.readAsDataURL(file);
});

export { readFileAsDataURL };

const zeroPad = (num, places) => String(num).padStart(places, '0');

const getCurrentDateString = () => {
  const today = new Date();
  return `${today.getFullYear()}-${zeroPad(today.getMonth() + 1, 2)}-${zeroPad(today.getDate(), 2)}`;
};

export { getCurrentDateString };
