/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete as MuiAutocomplete,
  TextField,
  CircularProgress,
  createFilterOptions,
} from '@mui/material';

const AutocompleteWithAdd = ({
  value, setValue, options, label,
  optionToString, optionKey, optionChanged,
  loading, freeSolo, error, helperText, id, formikHandleChange,
  ...rest
}) => {
  const onOptionChange = (option) => {
    if (typeof option === 'string') {
      setValue(option);
    } else if (option?.inputValue) {
      // Create a new value from the user input
      setValue(option.inputValue);
    } else {
      setValue(optionToString(option));
      optionChanged(option);
    }
  };

  const getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.title;
    }
    // Regular option
    return optionToString(option);
  };

  const filter = createFilterOptions();
  return (
    <MuiAutocomplete
      value={value}
      onChange={(event, newValue) => onOptionChange(newValue)}
      // eslint-disable-next-line no-shadow
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => (
          inputValue === optionToString(option)
        ));
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }}
      freeSolo={freeSolo}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option) => (
        <li {...props} key={optionKey(option)}>{getOptionLabel(option)}</li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
              </>
            ),
          }}
        />
      )}
      fullWidth
      {...rest}
    />
  );
};

AutocompleteWithAdd.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  optionToString: PropTypes.func.isRequired,
  optionKey: PropTypes.func.isRequired,
  optionChanged: PropTypes.func,
  loading: PropTypes.bool,
  freeSolo: PropTypes.bool
};

AutocompleteWithAdd.defaultProps = {
  value: '',
  optionChanged: () => { },
  loading: false,
  freeSolo: true
};

export default AutocompleteWithAdd;
