/* eslint-disable no-shadow */
import * as React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function ReportTable({
  header, items, itemToRow, keyAtIdx, pagination, page, rowsPerPage, handlePageChange,
}) {
  const emptyRows = Math.max(0, rowsPerPage - items.length);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow key="header">
            { header.map((obj, idx) => (<TableCell key={obj} align={idx === 0 ? 'inherit' : 'right'}>{obj}</TableCell>)) }
          </TableRow>
        </TableHead>
        <TableBody>
          { items.map(itemToRow).map((row, idx) => (
            <TableRow
              key={keyAtIdx(idx)}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              { row.map((col, idx) => (<TableCell key={col} align={idx === 0 ? 'inherit' : 'right'}>{col}</TableCell>)) }
            </TableRow>
          )) }
          { emptyRows > 0 && Array.from({ length: emptyRows }, (_, i) => (
            <TableRow key={i} style={{ height: 33 }}>
              <TableCell colSpan={header.length} />
            </TableRow>
          )) }
        </TableBody>
        <TableFooter>
          { pagination && (
          <TableRow>
            <TablePagination
              count={-1}
              onPageChange={(e, page) => handlePageChange(page)}
              page={page}
              rowsPerPage={Math.max(items.length, rowsPerPage)}
              rowsPerPageOptions={[]}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
            />
          </TableRow>
          ) }
        </TableFooter>

      </Table>
    </TableContainer>
  );
}

ReportTable.propTypes = {
  header: PropTypes.arrayOf(PropTypes.string).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemToRow: PropTypes.func.isRequired,
  keyAtIdx: PropTypes.func.isRequired,
  pagination: PropTypes.bool,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  handlePageChange: PropTypes.func,
};

ReportTable.defaultProps = {
  pagination: true,
  page: 0,
  rowsPerPage: 10,
  handlePageChange: () => {},
};
