import React from 'react';
import {
  BrowserRouter as Router, Route, Redirect, Switch
} from 'react-router-dom';

import { PrivateRoute } from 'stockpoint-common';
import { PromoBaseProvider } from '../context/PromoBaseContext';
import Dashboard from '../Dashboard';
import Login from '../Login';
import Promos from '../promos/Promos';
import Reports from '../reports/Reports';
import RewardCategories from '../rewards/categories/RewardCategories';
import Rewards from '../rewards/Rewards';
import Stockpoints from '../stockpoints/Stockpoints';
import * as path from './path'


export const Routing = () => {
  return (<Switch>
    <Route path={path.SIGN_IN} component={Login} />
    <PrivateRoute exact path={path.DASHBOARD} component={Dashboard} />
    <Redirect from={path.SIGN_OUT} to={path.SIGN_IN} />
    <PrivateRoute path={path.REPORTS} component={Reports} />
    <PrivateRoute path={path.STOCKPOINTS} component={Stockpoints} />
    <PrivateRoute path={path.REWARDS} component={Rewards} />
    <PrivateRoute path={path.REWARD_CATEGORIES} component={RewardCategories} />
    <PrivateRoute path={path.PROMO} component={()=>(<PromoBaseProvider>
      <Promos />
    </PromoBaseProvider>)} />
    <Redirect from={path.ANY} to={path.DASHBOARD} />
  </Switch>)
}
