/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { generateQR } from '../utils/generate';

const Tag = ({ product }) => {
  const [qrDataURL, setQRDataURL] = useState(null);

  useEffect(async () => {
    const imageDataURL = await generateQR(product.id);
    setQRDataURL(imageDataURL);
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      { qrDataURL && <img id="qr-img" src={qrDataURL} alt="QR" /> }
    </Box>
  );
};

export default Tag;
