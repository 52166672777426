/* eslint-disable react/prop-types */
import React, { useState, createContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingAlert from '../custom/loading/LoadingAlert';

export const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoadingValue] = useState(false);
  const [prevPath, setPrevPath] = useState(undefined);

  const animationTime = 750;
  const timeStart = useRef(new Date().getTime());
  const history = useHistory();

  const setIsLoading = (state) => {
    //if path not changed remove delay
    if (prevPath && history.location.pathname == prevPath) {
      setPrevPath(history.location.pathname);
      setIsLoadingValue(state);
      return;
    }
    
    //if path changed then show animation fulltime
    setPrevPath(history.location.pathname);
    if (state) {
      timeStart.current = new Date().getTime();
      setIsLoadingValue(state)
    } else {
      let timeFromStartMs = new Date().getTime() - timeStart.current
      if (timeFromStartMs < animationTime) {
        setTimeout(() => setIsLoadingValue(state), animationTime - timeFromStartMs)
      } else {
        setIsLoadingValue(state)
      }
    }
  }

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      <LoadingAlert isOpen={isLoading} />
      {children}
    </LoadingContext.Provider>
  );
};
