import React from 'react';
import {
  BrowserRouter as Router, Route, Redirect, Switch,
} from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LoadingProvider } from './context/LoadingContext';
import {
  AuthProvider, ErrorProvider, PrivateRoute,
  defaultTheme, AppVersion, isDevelopment,
} from 'stockpoint-common';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../api/fire';
import Navigation from './Navigation';
import { DashboardProvider } from './DashboardContext';
import { Routing } from './routing/Routing';


const useStyles = makeStyles(() => ({
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: "59px 0px 0px 0px",
    height: '100vh',
    // background: 'yellow',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // background: 'green',
  },
}));

const App = () => {
  const classes = useStyles();
  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline />
          <Router>
            <Box className={classes.topContainer}>
              <AuthProvider onAuthChange={(cb) => onAuthStateChanged(auth, cb)}>
                <ErrorProvider>
                  <LoadingProvider>
                    <DashboardProvider>
                      <Navigation />
                      <Box className={classes.contentContainer}>
                        <Routing></Routing>
                      </Box>
                      {isDevelopment && <AppVersion />}
                    </DashboardProvider>
                  </LoadingProvider>
                </ErrorProvider>
              </AuthProvider>
            </Box>
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default App;
