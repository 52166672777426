/* eslint-disable no-shadow */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField, Autocomplete as MuiAutocomplete, CircularProgress,
} from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

export default function AsyncAutocomplete({
  label, value, setValue,
  optionKey, optionToString, optionLabel,
  fetchOptions, onOptionClick,
}) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const onInputChange = async (newValue) => {
    if (newValue && newValue.length > 0) {
      try {
        setLoading(true);
        const results = await fetchOptions(newValue);
        setOptions(results);
      } catch (err) {
        setOptions([]);
      } finally {
        setLoading(false);
      }
    }
  };

  const debouncedInputChange = useDebouncedCallback(
    (value) => onInputChange(value),
    500,
  );

  return (
    <MuiAutocomplete
      freeSolo
      autoSelect
      value={value}
      onChange={(e, newValue) => {
        if (typeof newValue === 'string') {
          setValue(newValue);
        } else {
          setValue(optionToString(newValue));
          onOptionClick(newValue);
        }
      }}
      isOptionEqualToValue={(option, value) => (option === value)}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Regular option
        return optionLabel(option);
      }}
      options={options}
      loading={loading}
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={optionKey(option)}>{optionLabel(option)}</li>
      )}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label={label}
          onChange={(e) => debouncedInputChange(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      fullWidth
    />
  );
}

AsyncAutocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
  optionKey: PropTypes.func.isRequired,
  optionToString: PropTypes.func.isRequired,
  optionLabel: PropTypes.func.isRequired,
  fetchOptions: PropTypes.func.isRequired,
  onOptionClick: PropTypes.func.isRequired,
};
