import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button, Container, Stack, TextField } from '@mui/material';
import * as yup from 'yup';
import { useContext } from 'react';
import client from '../../../api';
import { LoadingContext } from '../../context/LoadingContext';
import { ErrorContext, Toast } from 'stockpoint-common';
import AutocompleteWithAdd from '../../../controls/AutocompleteWithAdd';





const validationSchema = yup.object({
    name: yup
        .string('Enter category name')
        .required('Category name is required'),
    sort_id: yup
        .number('Sort value must be a number')
        .positive('Enter only positive value')
        .required('Sort value is required'),
});


export const RewardCategoriesUpsertForm = (props) => {
    const { setIsLoading } = useContext(LoadingContext);
    const { setError } = useContext(ErrorContext);
    const [submitData, setSubmitData] = useState(null);

    const formik = useFormik({
        initialValues: {
            id: props.categoryEdit !== undefined ? props.categoryEdit.id : null,
            name: props.categoryEdit !== undefined ? props.categoryEdit.name : '',
            sort_id: props.categoryEdit !== undefined ? props.categoryEdit.sort_id : props.maxSortId ?? 10,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (props.categoryEdit !== undefined) {
                await handleEdit(values);
                props.handleClose(true);
            } else {
                props.setNeedUpdate(true);
                delete values.id;
                await handleCreate(values);
                values.name = '';
                values.sort_id = '';
                formik.resetForm({ values: values })
            }
        },
    })


    const handleEdit = async (formValues) => {
        try {
            setIsLoading(true);
            const resp = await client.post('reward-category-edit', formValues);
            setSubmitData(resp);
        } catch (err) {
            setError(err.response.data.error);
        } finally {
            setIsLoading(false);
        }
    }


    const handleCreate = async (formValues) => {
        try {
            setIsLoading(true);
            const resp = await client.post('reward-category-add', formValues);
            setSubmitData(resp);
        } catch (err) {
            setError(err.response.data.error);
        } finally {
            setIsLoading(false);
        }
    }


    return (
        <>
            <Container maxWidth="sm"
                sx={{ textAlign: "center" }}>
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <TextField
                            id="name"
                            name="name"
                            placeholder="Category name"
                            label='Name'
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <TextField
                            id="sort_id"
                            name="sort_id"
                            placeholder="Sort value"
                            label='Sort value'
                            value={formik.values.sort_id}
                            onChange={formik.handleChange}
                            error={formik.touched.sort_id && Boolean(formik.errors.sort_id)}
                            helperText={formik.touched.sort_id && formik.errors.sort_id}
                        />
                        <Button variant="contained" type="submit">Submit</Button>
                    </Stack>
                </form>
            </Container>
            <Toast
                isOpen={!!submitData}
                dismiss={() => setSubmitData(null)}
                message="Successful!"></Toast>
        </>
    )
};

