/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { ProductStatus, isDevelopment } from 'stockpoint-common';
import { Box } from '@mui/material';

const ProductCard = ({
  product, onSelect, showTag, handlePrint,
}) => {
  const title = [
    product.model,
    product.color,
    product.size,
  ].join(', ');

  const fields = [
    ['id', product.id],
    ['prodDate', product.prod_date],
    ['collection', product.collection.name],
    ['status', ProductStatus.nameOf(product.status)],
  ];

  return (
    <Card sx={{ width: 300 }} style={{ display: "flex", flexDirection: 'column', justifyContent: 'space-between' }}>
      <CardActionArea onClick={onSelect}>
        <Box style={{ display: "flex", justifyContent: "center", maxWidth: "100%" }}>
          <CardMedia
            component="img"
            image={product.image_url}
            sx={{ height: 200 }}
            style={{ objectFit: "contain", borderRadius: "6px", maxWidth: "100%", width: "auto" }}
          />
        </Box>
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
            {title}
          </Typography>
          {fields.map((pair, idx) => {
            const [key, value] = pair;
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={idx}>
                {value && (
                  <Typography variant="body2" color="textSecondary">
                    {`${key}: ${value}`}
                  </Typography>
                )}
              </Fragment>
            );
          })}
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          startIcon={<CropFreeOutlinedIcon />}
          onClick={showTag}
        >
          Show
        </Button>
        <Button
          size="small"
          color="primary"
          startIcon={<LocalPrintshopOutlinedIcon />}
          onClick={handlePrint}
        >
          Print
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProductCard;
