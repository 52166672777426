import { Card, CardContent, Divider, Grid, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import StarBorderIcon from '@mui/icons-material/StarBorder';


export default function PromoProductTableItem({ product, promoVisible = true }) {
    return (
        <Grid container>
            <Grid item xs={12} style={{ marginBottom: "8px" }}>
                <Stack
                    spacing={2}
                    justifyContent="flex-start"
                    alignItems="start">
                    <Typography sx={{ fontSize: 9 }} color="text.secondary" gutterBottom>
                        Model
                    </Typography>
                    {product.model}
                </Stack>
            </Grid>


            <Grid item xs={6} style={{ marginBottom: "8px" }}>
                <Stack
                    spacing={2}
                    justifyContent="flex-start"
                    alignItems="start">
                    <Typography sx={{ fontSize: 9 }} color="text.secondary" gutterBottom>
                        Color
                    </Typography>
                    {product.color}
                </Stack>
            </Grid>
            <Grid item xs={6} style={{ marginBottom: "8px" }}>
                <Stack
                    spacing={2}
                    justifyContent="flex-start"
                    alignItems="start">
                    <Typography sx={{ fontSize: 9 }} color="text.secondary" gutterBottom>
                        Size
                    </Typography>
                    {product.size}
                </Stack>
            </Grid>

            {promoVisible && (
                <Grid item xs={12}>
                    <Stack
                        spacing={2}
                        justifyContent="flex-start"
                        alignItems="start">
                        <Typography sx={{ fontSize: 9 }} color="text.secondary" gutterBottom>
                            Promo
                        </Typography>
                        <Stack direction="row" style={{ marginTop: "0px" }}
                            justifyContent="flex-start"
                            alignItems="start">
                            <Typography sx={{ fontSize: 'default' }} style={{ paddingRight: "10px" }}>
                                {product.promo_name}
                            </Typography>
                            <Stack direction="row" style={{ marginTop: "0px" }}
                                justifyContent="flex-start"
                                alignItems="start">
                                {product.promo_star_value}
                                <StarBorderIcon sx={{ fontSize: 18 }}></StarBorderIcon>
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
            )}
        </Grid>
    )
}
