import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, DialogActions, Button,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import ImagePreview from './ImagePreview';


const ImageDialog = ({
  isOpen, title, imageName, imageURL, handleClose, updateImageURL, imagesFodler, imageSize
}) => {
  const [imageSelected, setImageSelected] = useState(false);
  const imagePrevRef = useRef();
  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <ImagePreview
            ref={imagePrevRef}
            imageName={imageName}
            imageURL={imageURL}
            handleSelected={() => setImageSelected(true)}
            updateImageURL={updateImageURL}
            imagesFodler={imagesFodler}
            imageSize={imageSize}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            startIcon={<CloseOutlinedIcon />}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            startIcon={<PhotoLibraryOutlinedIcon />}
            color="primary"
            onClick={async () => {
              await imagePrevRef.current.selectImage();
            }}
          >
            Select
          </Button>
          <Button
            startIcon={<DoneOutlinedIcon />}
            color="primary"
            onClick={async () => {
              await imagePrevRef.current.uploadImage();
              handleClose();
            }}
            disabled={!imageSelected}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ImageDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  imageName: PropTypes.string.isRequired,
  imageURL: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  updateImageURL: PropTypes.func.isRequired,
  imagesFodler: PropTypes.string,
  imageSize: PropTypes.object
};

ImageDialog.defaultProps = {
  isOpen: false,
  title: 'Upload image',
  imageURL: null,
  imagesFodler: 'images',
  imageSize: { MAX_WIDTH: 500, MAX_HEIGHT: 500 }
};

export default ImageDialog;
