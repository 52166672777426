import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Button, Container, Stack, TextField } from '@mui/material';
import * as yup from 'yup';
import { useContext } from 'react';
import client from '../../api';
import { LoadingContext } from '../context/LoadingContext';
import { ErrorContext, Toast } from 'stockpoint-common';





const validationSchema = yup.object({
    name: yup
        .string('Enter stockpoint name')
        .required('Stockpoint name is required'),
        company_address: yup
        .string('Enter stockpoint company address')
        .required('Stockpoint company address is required'),
    country: yup
        .string('Enter stockpoint country')
        .required('Stockpoint country is required'),
    city: yup
        .string('Enter stockpoint city')
        .required('Stockpoint city is required'),
});


export const StockpointUpsertForm = (props) => {

    const { setIsLoading } = useContext(LoadingContext);
    const { setError } = useContext(ErrorContext);
    const [submitData, setSubmitData] = useState(null);

    const formik = useFormik({
        initialValues: {
            id: props.stockpointEdit !== undefined ? props.stockpointEdit.id : null,
            name: props.stockpointEdit !== undefined ? props.stockpointEdit.name : '',
            company_address: props.stockpointEdit !== undefined ? props.stockpointEdit.company_address : '',
            country: props.stockpointEdit !== undefined ? props.stockpointEdit.country : '',
            city: props.stockpointEdit !== undefined ? props.stockpointEdit.city : '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (props.stockpointEdit !== undefined) {
                await handleEdit(values);
                props.handleClose(true);
            } else {
                props.setNeedUpdate(true);
                delete values.id;
                await handleCreate(values);
                values.name = '';
                formik.resetForm({ values: values })
            }
        },
    })


    const handleEdit = async (formValues) => {
        try {
            setIsLoading(true);
            const resp = await client.post('stockpoint-edit', formValues);
            setSubmitData(resp);
        } catch (err) {
            setError(err.response.data.error);
        } finally {
            setIsLoading(false);
        }
    }


    const handleCreate = async (formValues) => {
        try {
            setIsLoading(true);
            const resp = await client.post('stockpoint-add', formValues);
            setSubmitData(resp);
        } catch (err) {
            setError(err.response.data.error);
        } finally {
            setIsLoading(false);
        }
    }


    return (
        <>
            <Container maxWidth="sm"
                sx={{ textAlign: "center" }}>
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <TextField
                            id="name"
                            name="name"
                            placeholder="Stockpoint name"
                            label='Name'
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <TextField
                            id="company_address"
                            name="company_address"
                            placeholder="Stockpoint company address"
                            label='Company address'
                            value={formik.values.company_name}
                            onChange={formik.handleChange}
                            error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                            helperText={formik.touched.company_name && formik.errors.company_name}
                        />
                        <TextField
                            id="country"
                            name="country"
                            placeholder="Stockpoint country"
                            label='Country'
                            value={formik.values.country}
                            onChange={formik.handleChange}
                            error={formik.touched.country && Boolean(formik.errors.country)}
                            helperText={formik.touched.country && formik.errors.country}
                        />
                        <TextField
                            id="city"
                            name="city"
                            placeholder="Stockpoint city"
                            label='City'
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            error={formik.touched.city && Boolean(formik.errors.city)}
                            helperText={formik.touched.city && formik.errors.city}
                        />
                        <Button variant="contained" type="submit">Submit</Button>
                    </Stack>
                </form>
            </Container>
            <Toast
                isOpen={!!submitData}
                dismiss={() => setSubmitData(null)}
                message="Successful!"></Toast>
        </>
    )
};

