/* eslint-disable no-shadow */
import React, { useContext, useState, useEffect } from 'react';
import {
  Stack, Typography, FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';
import { LoadingContext } from '../context/LoadingContext';
import { ErrorContext, ProductStatus } from 'stockpoint-common';
import ReportTable from './ReportTable';
import client from '../../api';

const ProductsReport = () => {
  const { setIsLoading } = useContext(LoadingContext);
  const { setError } = useContext(ErrorContext);

  const [status, setStatus] = useState(ProductStatus.options[1].value);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);

  const [summary, setSummary] = useState(null);

  const loadData = async (status, page) => {
    try {
      setError(null);
      setIsLoading(true);
      const limit = 10;
      const params = { q: `status:${status}`, offset: page * limit, limit };
      const resp = await client.get('/products', { params });
      setData(resp.data.data);
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const loadSummary = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const resp = await client.get('/summary-report');
      setSummary(resp.data.data);
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData(status, page);
  }, [status, page]);

  useEffect(() => {
    loadSummary();
  }, []);

  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="h6">
          Products
        </Typography>

        {summary && (
          <FormControl>
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              value={status}
              onChange={(e) => { setPage(0); setStatus(e.target.value); }}
            >
              {ProductStatus.options.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.name}
                  {' '}
                  (
                  {summary.products[opt.value]}
                  )
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>

      {data && (
        <ReportTable
          header={[
            /* 'Id', */'Model', 'Color',
            'Size', 'ProdDate', 'Collection',
            'Stockpoint',
            'Profile',
          ]}
          items={data}
          itemToRow={(obj) => [
            /* obj.id, */obj.model, obj.color,
            obj.size, obj.prod_date, obj.collection.name,
            obj.stockpoint ? [obj.stockpoint?.country,
            obj.stockpoint.city,
            obj.stockpoint.name].join(', ') : '-',
            obj.profile.email,
          ]}
          keyAtIdx={(idx) => data[idx].id}
          page={page}
          handlePageChange={setPage}
        />
      )}
    </Stack>
  );
};

export default ProductsReport;
